import React from 'react'

const LinkedInIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.1525 5.9975C5.15949 5.9975 5.97583 5.18116 5.97583 4.17416C5.97583 3.16716 5.15949 2.35083 4.1525 2.35083C3.1455 2.35083 2.32916 3.16716 2.32916 4.17416C2.32916 5.18116 3.1455 5.9975 4.1525 5.9975Z" fill="black"/>
<path d="M7.6975 7.37915V17.495H10.8383V12.4925C10.8383 11.1725 11.0867 9.89415 12.7233 9.89415C14.3375 9.89415 14.3575 11.4033 14.3575 12.5758V17.4958H17.5V11.9483C17.5 9.22332 16.9133 7.12915 13.7283 7.12915C12.1992 7.12915 11.1742 7.96832 10.755 8.76248H10.7125V7.37915H7.6975V7.37915ZM2.57916 7.37915H5.725V17.495H2.57916V7.37915Z" fill="black"/>
</svg>

    )
}

export default LinkedInIcon;
